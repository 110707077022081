import { AxiosError } from 'axios';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { HttpData } from '~/utils/network/HttpData';

export function SetFormError<V, T extends FieldValues>(
  setError: UseFormSetError<T>,
  fallbackMessageTranslation: string,
  err?: Error
) {
  const aErr = err as AxiosError<HttpData<V>>;
  setError('root', {
    type: 'server',
    message:
      aErr != undefined
        ? aErr.response?.data.app_code ?? fallbackMessageTranslation
        : fallbackMessageTranslation,
  });
}
